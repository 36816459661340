import React from 'react'
import './PageTitle.scss'

export default (props) => {
    return (
        <div className="page-title-container">
            <h1 className="page-title-container__title">{props.title}</h1>
            <div className="page-title-container__text">
                {props.children}
            </div>
        </div>
    )
}