import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle/PageTitle'
import faqs from '../data/faq.json';
import ContentBlock from '../components/LandingContentBlock/ContentBlock';
import Accordian from '../components/Accordian/Accordian';
import SEO from '../components/SEO/SEO';

export default () => (
    <Layout>
        <SEO
            title='Zest 4 Maths | FAQs | Maths Tuition Christchurch, NZ' 
            description='Find all your answers here for private maths tuition by Karen Benn at Zest 4 Maths, Christchurch.'/>
        <PageTitle title="Frequently Asked Questions">
            Got a question? Get started below and feel free to get in touch.
        </PageTitle>
        <ContentBlock class={'sm-content'}>
            {faqs.map((faq) => {
                return (
                    <Accordian question={faq.question} answer={faq.answer} />
                )
            })}
        </ContentBlock>

    </Layout>
)