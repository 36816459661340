import React from 'react';
import './Accordian.scss'


export default (props) => {

    const [isOpen, setOpen] = React.useState(false);
    
    return (
        <div className='accordion-wrapper'>
            <div className={`accordion-title ${isOpen ? "open" : ""}`} onClick={() => setOpen(!isOpen)}>
                {props.question}
            </div>
            <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className={`accordion-content`}>
                    {props.answer}
                </div>
            </div>
        </div>
    )
}